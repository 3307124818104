var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("img", {
              staticStyle: { "max-height": "150px" },
              attrs: { src: _vm.$root.$data.portalInfo.logoMain }
            }),
            _c("h1", [_vm._v("Account blocked")])
          ])
        ],
        1
      ),
      _vm.snippetName
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [_c("page-snippet", { attrs: { name: _vm.snippetName } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [_c("b-col", { domProps: { innerHTML: _vm._s(_vm.html) } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }