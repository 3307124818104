<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <img style="max-height: 150px;" :src="$root.$data.portalInfo.logoMain" />
        <h1>Account blocked</h1>
      </b-col>
    </b-row>
    <b-row v-if="snippetName">
      <b-col>
        <page-snippet :name="snippetName"></page-snippet>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-html="html">
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    components: { 
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    name: 'Blocked',
    props: {
      snippetName: String,
      html: String,
    },
    methods: {
    },
    mounted () {
    }
  }
</script>